import React from 'react'
import Ecosystem from '../components/Ecosystem'
import page from '~utils/page'
import { RESOURCES } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(Ecosystem, {
    highlight: RESOURCES,
    headComponent: (
        <Head
            mediaDescription="Partnerships, projects and community"
            // eslint-disable-next-line max-len
            description="Explore the Streamr ecosystem, from formal partnerships to simpler collaborations and community projects. You can also propose a collaboration."
        />
    ),
})
