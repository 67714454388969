import React, { forwardRef } from 'react'
import { css } from 'styled-components'
import Org from './Org'
import atarca from './assets/atarca.svg'
import bosch from './assets/bosch.svg'
import hpe from './assets/hpe.svg'
import wwf from './assets/wwf.svg'
import mptc from './assets/mptc.svg'
import atos from './assets/atos.svg'
import lynkeus from './assets/lynkeus.svg'
import unionBank from './assets/unionBank.svg'
import gdst from './assets/gdst.svg'
import api3 from './assets/api3.svg'
import fbk from './assets/fbk.svg'
import xlab from './assets/xlab.svg'
import tuGraz from './assets/tuGraz.svg'
import ait from './assets/ait.svg'
import rx from './assets/rx.svg'
import kuLeuven from './assets/kuLeuven.svg'
import swash from './assets/swash.svg'
import kraken from './assets/kraken.svg'
import binance from './assets/binance.svg'
import uniswap from './assets/uniswap.svg'
import messari from './assets/messari.svg'
import chainlink from './assets/chainlink.svg'
import tx from './assets/tx.svg'
import blockScience from './assets/blockScience.svg'
import certik from './assets/certik.svg'
import pave from './assets/pave.svg'
import dimo from './assets/dimo.svg'
import republic from './assets/re-public.svg'
import unbanks from './assets/unbanks.svg'
import helium from './assets/helium.svg'
import ethereum from './assets/ethereum.svg'
import polygon from './assets/polygon.svg'
import gnosis from './assets/gnosis.svg'
import arweave from './assets/arweave.svg'
import lit from './assets/lit.svg'
import kyve from './assets/kyve.svg'
import redStone from './assets/redStone.svg'
import { Link, LinkLook } from 'streamr-ui'
import ReactMarkdown from 'react-markdown'
import { normalizeWhitespace } from 'streamr-ui/utils'

function defaultLinkText(name) {
    return `→ Visit ${name}`
}

function forge(name, url, iconSrc, linkText = defaultLinkText) {
    return ([desc]) => {
        return forwardRef(function ForgedComponent(props, ref) {
            return (
                <Org {...props} ref={ref} name={name} icon={<img src={iconSrc} alt="" />} url={url}>
                    <ReactMarkdown>{normalizeWhitespace(desc)}</ReactMarkdown>
                    <p>
                        <Link
                            blank
                            look={[
                                LinkLook.Basic,
                                css`
                                    --color: #0421ff;
                                `,
                            ]}
                            href={url}
                        >
                            {typeof linkText === 'string' ? linkText : linkText(name)}
                        </Link>
                    </p>
                </Org>
            )
        })
    }
}

export const Atarca = forge('Atarca', 'https://atarca.eu/', atarca)`
    Atarca is a consortium awarded €2.75m by the EU in a competitive bidding process to research anti-rival tokens,
    a kind of token designed to increase in value when shared.
`

export const Bosch = forge('Bosch', 'https://www.bosch.com', bosch)`
    Bosch Software Innovations partnered with Streamr to deliver a pilot project
    that gathered a range of electric vehicle data from a Jaguar I-PACE and shared
    it openly in real-time.
`

export const HPE = forge('HPE', 'https://www.hpe.com', hpe)`
    Streamr's partnership with HPE saw realtime vehicle data integrated to the Streamr
    Network. HPE collected information in realtime from the communications “bus”
    in the car, including fuel consumption and location, to acceleration and gearing,
    which was then sent straight to the Streamr platform.
`

export const WWF = forge('WWF', 'https://wwf.org.ph', wwf)`
    WWF are co-founders of the Tracey project. They are providing access to two
    Fisheries Improvements project sites where pilots will be performed using the
    Tracey application. Other WWF activities include community engagement,
    on-boarding to the app, expert fisheries knowledge and access to global markets
    such as major retail chains.
`

export const MPTC = forge('MPTC', 'http://mptc.com.ph', mptc)`
    MPTC have been working with Streamr's commercial subsidiary TX to undertake
    a study that would assess the opportunities for engineering value from data
    produced by their various toll road assets and road users through direct and
    indirect data monetisation.
`

export const Atos = forge('Atos', 'https://atos.net/en/', atos)`
    Coordinator of the EU Horizon 2020 project KRAKEN. Atos is a large multinational
    IT service and consulting company working on integration of a self-sovereign
    identity solution with the KRAKEN data marketplace to be built on the
    Streamr Stack.
`

export const Lynkeus = forge('Lynkeus', 'http://www.lynkeus.eu', lynkeus)`
    Consortium members of the KRAKEN project. Lynkeus are integrating their
    blockchain-enabled MyHealthMyData system with the Streamr tech stack to power
    a GDPR-compliant biomedical and wellness data marketplace.
`

export const UnionBank = forge('Union Bank', 'https://www.unionbankph.com', unionBank)`
    Partners in the Tracey fisheries tracing & microloans project. UnionBank are
    providing crypto wallets, micro-loan assessments and delivery.
`

export const GDST = forge('GDST', 'https://traceability-dialogue.org', gdst)`
    The Global Dialogue on Seafood Traceability. Streamr is a member due to its
    participation in the Tracey project run by TX.
`

export const API3 = forge('API3', 'https://api3.org', api3)`
    API3 enables blockchain native, decentralized APIs. Streamr is to participate
    in the governance of the API3 DAO. It will integrate with the API3 platform to
    provide data streams to their protocol.
`

export const FBK = forge('Fondazione Bruno Kessler (FBK)', 'https://www.fbk.eu/en/', fbk)`
    Consortium members of the KRAKEN Project. FBK contributes to user research for
    the definition of user stories and in conducting user-centred validations of
    KRAKEN's interactive solutions.
`

export const XLAB = forge('XLAB', 'https://www.xlab.si', xlab)`
    Consortium members of the KRAKEN project. The R&D company XLAB are building the
    privacy-preserving data analytics algorithms that will be integrated with the
    KRAKEN data marketplace.
`

export const TUGraz = forge(
    'Graz University of Technology (TU Graz)',
    'https://www.tugraz.at',
    tuGraz,
)`
    Consortium members of the KRAKEN Project. TU Graz will provide the privacy
    enhancing cryptographic framework relied upon by the self-sovereign identity
    (SSI) system to be integrated with the KRAKEN data marketplace.
`

export const AIT = forge(
    'Austrian Institute of Technology (AIT)',
    'https://www.ait.ac.at/en/',
    ait,
)`
    Consortium members of the KRAKEN Project. AIT are contributing towards the
    design and prototyping of the cryptographic primitives underlying KRAKEN and
    leading on the development of the project's privacy metrics.
`

export const RadicalXchange = forge('RadicalXchange', 'https://www.radicalxchange.org', rx)`
    Data Union advisory board members are also members of RadicalXchange, giving
    some information flow between the two projects.
`

export const KULeuven = forge('KU Leuven', 'https://www.kuleuven.be/english/', kuLeuven)`
    Consortium members of the KRAKEN Project. A research centre at the Faculty of
    Law of the University of Leuven. Responsible for the tasks concerning the legal
    and ethical aspects of the KRAKEN project.
`

export const Swash = forge('Swash', 'https://swashapp.io', swash)`
    The world's first Data Union, Swash allows its users to get paid in crypto to
    browse the web. They have seed funding from Outlier Ventures.
`

export const Kraken = forge('Kraken', 'https://www.krakenh2020.eu/the_project/consortium', kraken)`
    Streamr technology is being used by the consortium working on Kraken, a EU
    Horizon 2020 project to build a data marketplace for personal data in the health
    and education sectors.
`

export const Binance = forge('Binance', 'https://www.binance.com?ref=IGUXHN0B', binance)`
    Leading centralised exchange with active trading of the DATA token
`

export const Uniswap = forge('Uniswap', 'https://uniswap.org', uniswap)`
    Leading decentralised exchange providing a sizable liquidity pool for decentralised
    trading of the DATA token.
`

export const Messari = forge('Messari', 'https://messari.io', messari)`
    Streamr project participates in Messari's open source disclosure registry as
    part of a committment to transparency and good governance.
`

export const Chainlink = forge('Chainlink', 'https://chain.link', chainlink)`
    Oracles can use Streamr as a data source to deliver data to smart contracts via
    an integration built by Chainlink.
`

export const TX = forge('TX', 'https://tx.company', tx)`
    A subsidiary of Streamr AG, who provide consulting and development services to
    anyone building on the Streamr tech stack. Typically they will deliver any
    external technical projects like pilots or partnerships.
`

export const BlockScience = forge('BlockScience', 'https://block.science', blockScience)`
    Blockchain technical specialists helping to devise and test the tokenomics for
    the Streamr Network.
`

export const Certik = forge('CertiK', 'https://www.certik.com/', certik)`
    Smart contract auditing professionals who provided security audits of the Data
    Unions smart contract.
`

export const PaveMotors = forge('Pave Motors', 'https://www.pavemotors.com', pave)`
    Pave is building lightweight electric bikes unlocked with ERC-721 tokens,
    with each bike relaying its metrics data via the Streamr Network,
    offering a new decentralized business model for mobility.
`

export const Dimo = forge('DIMO', 'https://dimo.zone/', dimo)`
    DIMO is the first Data Union for real-time vehicle data. DIMO is an extensible,
    scalable open IoT software platform that allows drivers to stream their vehicle data in exchange for tokens.
`

export const RePublic = forge('Re-public', 'https://re-public.io/', republic)`
    Re-public is a mobile app that lets you manage, collect and monetize your personal data.
    This is made possible by leveraging Streamr's Data Union framework.
`

export const Unbanks = forge('Unbanks', 'https://www.unbanks.me/', unbanks)`
    Monetize your own banking data with Unbanks, the world's first consumer banking
    data union.
`

export const Ethereum = forge('Ethereum', 'https://ethereum.org/en/', ethereum)`
    Ethereum is a decentralized, open-source blockchain platform that enables the creation of smart
    contracts and decentralized applications. Streamr uses the Ethereum blockchain to secure and
    verify data streams on its network, as well as for payments in its native, ERC-20 DATA token.
`

export const Polygon = forge('Polygon', 'https://polygon.technology/', polygon)`
    Polygon is an open-source, Ethereum scaling solution that utilizes a network of side chains to
    increase transaction throughput and lower costs. Streamr uses Polygon, to improve the speed and
    scalability of its network. In addition, Streamr runs a Polygon validator node that helps to
    secure the Polygon network.
`

export const Gnosis = forge('Gnosis', 'https://www.gnosis.io/', gnosis)`
    Streamr uses the Gnosis chain to host Data Unions and to monetise data on the Network.
`

export const Helium = forge(
    'Helium',
    'https://medium.com/streamrblog/helium-streamr-an-end-to-end-pipeline-for-connecting-delivering-and-monetizing-iot-data-af0f857688a8',
    helium,
    '→ Learn more',
)`
    Streamr ecosystem projects utilize the Helium network for decentralized coverage and
    connectivity for their IoT devices. Streamr pairs with Helium to then securely share and
    monetize those data flows in real time.
`

export const Arweave = forge('Arweave', 'https://www.arweave.org/', arweave)`
    Compatibility with existing decentralized decentralized storage is an inevitable step forward
    for the Streamr Network. Real time data flows will be able to rest at decentralized storage
    solutions such as Arweave in the near future.
`

export const Lit = forge('Lit', 'https://litprotocol.com/', lit)`
    The Streamr Network has a liveness requirement for private streaming data - for the key exchange
    to succeed, both the data publishers and data subscribers must be online at the same time. Lit
    connects with Streamr to remove this liveness requirement by securely taking shared custody
    of the publisher's keys to enable access to the data flows, independent of the data
    publisher's connected status.
`

export const Kyve = forge('Kyve', 'https://www.kyve.network/', kyve)`
    Kyve securely co-ordinates and validates decentralized networks of nodes. In Streamr's case,
    these nodes will be responsible for performing and validating the movement of data from Streamr
    data streams to various decentralized storage solutions. Data archived with Kyve is also
    indexed, such that data can be queried through a GraphQL interface.
`

export const RedStone = forge('RedStone', 'https://redstone.finance/', redStone)`
    RedStone delivers frequently updated, reliable and diverse data for dApps and smart contracts.
    RedStone uses Streamr as their layer-0 data transport for their oracle network.
`
