import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Regular, Medium, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Display from '~shared/Display'
import { Link, LinkLook } from 'streamr-ui'

const UnstyledAboutOrg = forwardRef(function UnstyledAboutOrg({ children, ...props }, ref) {
    return (
        <div {...props} ref={ref}>
            <div>{children}</div>
        </div>
    )
})

const AboutOrg = styled(UnstyledAboutOrg)`
    box-sizing: border-box;
    display: none;
    left: 0;
    margin-top: 16px;
    padding: 0 8px;
    pointer-events: none;
    position: absolute;
    width: 200%;
    z-index: 1;

    h4 {
        border-bottom: 1px solid #efefef;
        font-size: 16px;
        font-weight: ${Medium};
        line-height: 18px;
        margin: 0 0 18px;
        padding: 0 0 20px;
        color: #0c009a;
    }

    p {
        color: #0c009a;
        font-size: 12px;
        line-height: 1.5em;
        margin: 0;
    }

    p + p {
        margin-top: 32px;
    }

    > div {
        background: #ffffff;
        color: #323232;
        border-radius: 4px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        padding: 48px;
        pointer-events: auto;
    }

    @media ${MqTablet} {
        display: block;
    }
`

const Icon = styled.div`
    align-items: center;
    appearance: none;
    background: #f8f8f8;
    border-radius: 4px;
    border: 0;
    display: flex;
    height: 120px;
    justify-content: center;
    margin: 0;
    outline: 0 !important;
    padding: 0;
    position: relative;
    transition: 0.5s background-color;
    width: 100%;

    img {
        display: block;
        transform: scale(0.85);
    }

    @media ${MqTablet} {
        img {
            transform: scale(0.9);
        }
    }

    @media ${MqDesktop} {
        height: 136px;

        img {
            transform: scale(1);
        }
    }
`

const Inner = styled.div`
    &,
    ${Display} {
        height: 100%;
    }
`

const UnstyledOrg = forwardRef(function UnstyledOrg(
    { children, icon, name, url = '#', ...props },
    ref,
) {
    return (
        <div {...props} ref={ref}>
            {/* Don't annotate the outer div with `$appear`. CSS animations mess up z-indexing and break
                the on-hover popups. */}
            <Inner $appear>
                <Display mobile="block" tablet="none">
                    <Link href={url} blank>
                        <Icon>{icon}</Icon>
                    </Link>
                </Display>
                <Display mobile="none" tablet="block">
                    <Icon>{icon}</Icon>
                </Display>
            </Inner>
            <AboutOrg>
                <h4>{name}</h4>
                {children}
            </AboutOrg>
        </div>
    )
})

const Org = styled(UnstyledOrg)`
    padding: 0 8px;
    position: relative;

    ${AboutOrg} {
        opacity: 0;
        pointer-events: none;
        transform: translateY(8px);
        transition-delay: 0s;
        transition-duration: 0.4s;
        transition-property: visibility, opacity, transform;
        visibility: hidden;
        z-index: 1;
    }

    :hover ${AboutOrg} {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        transition-delay: 0.2s;
        transition-duration: 0.2s;
        visibility: visible;
        z-index: 2;
    }

    :hover ${Icon} {
        background: #efefef;
        transition-duration: 25ms;
    }

    :hover ${Icon}::after {
        background: none;
        bottom: -16px;
        content: '';
        height: 16px;
        left: 0;
        position: absolute;
        width: 100%;
    }
`

const UnstyledGroup = forwardRef(function UnstyledGroup({ name, children, ...props }, ref) {
    const id = name.toLowerCase().replace(/\s/g, '-')

    return (
        <div {...props} id={id} ref={ref}>
            <h3 $appear>
                <Link href={`#${id}`} look={[LinkLook.Inherit]}>
                    {name}
                </Link>
            </h3>
            <div>{children}</div>
        </div>
    )
})

const Group = styled(UnstyledGroup)`
    & + & {
        margin-top: 118px;
    }

    h3 {
        border-bottom: 1px solid #e7e7e7;
        font-size: 26px;
        font-weight: ${Regular};
        line-height: 32px;
        margin: 0 0 40px;
        padding: 0 0 20px;
    }

    > div {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 -8px;
        row-gap: 16px;
    }

    @media (min-width: 348px) {
        > div {
            grid-template-columns: 1fr 1fr;
        }

        > div > div:nth-child(2n) ${AboutOrg} {
            left: auto;
            right: 0;
        }
    }

    @media ${MqTablet} {
        > div {
            grid-template-columns: 1fr 1fr 1fr;
        }

        > div > div:nth-child(2n) ${AboutOrg} {
            left: 0;
            right: auto;
        }

        > div > div:nth-child(3n) ${AboutOrg} {
            left: auto;
            right: 0;
        }
    }

    @media ${MqDesktop} {
        > div {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        > div > div:nth-child(3n) ${AboutOrg} {
            left: 0;
            right: auto;
        }

        > div > div:nth-child(4n) ${AboutOrg} {
            left: auto;
            right: 0;
        }
    }
`

Object.assign(Org, {
    Icon,
    AboutOrg,
    Group,
})

export default Org
