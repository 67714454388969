import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Org from './Org'
import * as orgs from './Orgs'
import icon from './assets/ecosystem.png'
import icon2x from './assets/ecosystem@2x.png'
import PrestyledLayout from '~shared/Layout'
import Container from '~shared/Container'
import Clip from '~shared/Clip'
import Spacer from '~shared/Spacer'
import Display from '~shared/Display'
import { Headline, Paragraph } from '~/components/DataFund/Hero'

const Column = styled.div`
    margin: 0 auto;
    max-width: 312px;

    @media ${MqTablet} {
        max-width: 496px;
    }

    @media ${MqDesktop} {
        max-width: 736px;
    }
`

const Hero = styled(Column)`
    ${Clip} {
        margin: 0 auto;
    }

    @media ${MqTablet} {
        ${Clip} {
            margin: 0;
        }
    }
`

const Body = styled(Column)`
    margin-top: 110px;
    padding-bottom: 168px;
`

const Layout = styled(PrestyledLayout)`
    overflow: visible;
    z-index: 2;
`

function P(props) {
    return <Paragraph {...props} $appear />
}

const UnstyledEcosystem = (props) => (
    <Layout {...props} $backgroundColor="white">
        <Container>
            <Hero>
                <Spacer head="36px,118px,116px" $appear>
                    <Display mobile="block" tablet="none">
                        <Clip width={165} height={158} dx={0.105} dy={0.03} rect={[0.78, 0.89]}>
                            <img src={icon} srcSet={`${icon2x} 2x`} alt="" />
                        </Clip>
                    </Display>
                    <Display mobile="none" tablet="block">
                        <Clip width={225} height={215} dx={0.105} dy={0.03} rect={[0.78, 0.89]}>
                            <img src={icon} srcSet={`${icon2x} 2x`} alt="" />
                        </Clip>
                    </Display>
                    <Headline $appear>The Streamr Ecosystem</Headline>
                    <ReactMarkdown
                        components={{
                            p: P,
                        }}
                    >
                        Streamr collaborates with a number of other organisations in&nbsp;all kinds
                        of ways. From formal partnerships to simpler collaborations, DAO and
                        consortia memberships, and a&nbsp;range&nbsp;of service and&nbsp;ecosystem
                        partners. Explore our collaborations here and if you would
                        like&nbsp;to&nbsp;propose a collaboration and get featured here, please
                        [drop us an email](mailto:contact@streamr.network).
                    </ReactMarkdown>
                </Spacer>
            </Hero>
            <Body>
                <Org.Group name="Web3 ecosystem">
                    <orgs.Ethereum />
                    <orgs.Polygon />
                    <orgs.Gnosis />
                    <orgs.Helium />
                    <orgs.Arweave />
                    <orgs.Lit />
                    <orgs.Kyve />
                    <orgs.Binance />
                    <orgs.Uniswap />
                    <orgs.Messari />
                    <orgs.Chainlink />
                </Org.Group>
                <Org.Group name="Built on Streamr">
                    <orgs.RedStone />
                    <orgs.PaveMotors />
                    <orgs.Swash />
                    <orgs.Kraken />
                    <orgs.Dimo />
                    <orgs.RePublic />
                    <orgs.Unbanks />
                </Org.Group>
                <Org.Group name="Institutions and DAOs">
                    <orgs.GDST />
                    <orgs.API3 />
                    <orgs.FBK />
                    <orgs.XLAB />
                    <orgs.TUGraz />
                    <orgs.AIT />
                    <orgs.RadicalXchange />
                    <orgs.KULeuven />
                </Org.Group>
                <Org.Group name="Enterprises">
                    <orgs.Atarca />
                    <orgs.Bosch />
                    <orgs.HPE />
                    <orgs.WWF />
                    <orgs.MPTC />
                    <orgs.Atos />
                    <orgs.Lynkeus />
                    <orgs.UnionBank />
                </Org.Group>
                <Org.Group name="Service partners">
                    <orgs.TX />
                    <orgs.BlockScience />
                    <orgs.Certik />
                </Org.Group>
            </Body>
        </Container>
    </Layout>
)

const Ecosystem = styled(UnstyledEcosystem)`
    color: #0c009a;
`

export default Ecosystem
